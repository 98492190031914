import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Typography, InputAdornment, TextField, Grid, Toolbar, TablePagination } from "@mui/material";
import { fetchTaskReports } from "./UseHooks";
import { useQuery } from "react-query";
import { Search } from "@mui/icons-material";

const TaskReportsTable = ({ taskId }) => {
    const { data, isLoading, isError, refetch } = useQuery(["taskReports", taskId], () => fetchTaskReports(taskId));

    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

  // Filter data based on search query
  const filteredData = data?.filter((item) =>
    item.client_payment_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.amount_payed.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.payment_status.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.order_id.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination
  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) =>
    setRowsPerPage(parseInt(event.target.value, 10));

  // Paginate filtered data
  const paginatedData = filteredData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
    
      // Action buttons for each row (Edit, Delete, JS Code Snippet)
      
    
      // const handleDelete = (taskId) => {
      //   setTasks(tasks.filter((task) => task.id !== taskId));
      // };
  
      useEffect(() => {
        const interval = setInterval(() => {
          refetch(); // Refetch data every 5 seconds (5000 ms)
        }, 5000);
    
        return () => clearInterval(interval); // Cleanup on component unmount
      }, [refetch]);

    if (isLoading) {
        return <Box sx={{flex:1, alignItems:"center", justifyContent:"center"}}>
                    <Typography style={{fontSize:"14px", fontWeight:"500", color:"#ffff"}}>Loading.........</Typography>
                </Box>
    }

    if (isError) {
           <Box style={{backgroundColor:"#f8d7da",padding:"15px", borderRadius:"2px",border:"1px solid #f5c6cb", marginTop:"10px", marginBottom:"10px", maxWidth:"500px"}}>
              <Typography style={{ color:'#721c24', fontSize:"12px"}}>Error: Error loading reports.</Typography>
          </Box>
    } 
  return (
    <Box elevation={0} sx={{ mb:9, mt:5}}>
        <Toolbar sx={{mb:2}}>
           <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                   <TextField
                        label="Search"
                        variant="outlined"
                        fullWidth
                        placeholder="Search ... "
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        
                        sx={{
                            mb:2,
                            '& .MuiInputLabel-root': {
                            color: '#999', // Label color when not focused
                            fontSize:"small",
                            textTransform:"capitalize",
                            '&.Mui-focused': {
                                color: '#f5f5f5', // Label color when focused
                            },
                            },
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '2px', // Border radius
                            '& fieldset': {
                                borderColor: '#ddd', // Border color when not focused
                            },
                            '&:hover fieldset': {
                                borderColor: '#f5f5f5', // Border color on hover
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#f5f5f5', // Border color when focused
                            },
                            },
                        }}
                        InputProps={{
                            sx: {
                                fontSize:12,
                                textTransform:"capitalize",
                                color:"#dcdcdc",
                                '& input::placeholder': {
                                fontSize: 12, // Change placeholder font size here
                                color: '#555',
                                textTransform:"capitalize" // Optionally change color
                                },
                            },
                        startAdornment: (
                            <InputAdornment position="start">
                            <Search />
                            </InputAdornment>
                        ),
                        }}
                    />
                </Grid>
            </Grid>
        </Toolbar>
        <TableContainer  sx={{ 
        backgroundColor:"#001f3f",// Keeps the table within the width of its container
    overflow: 'scroll', // Allows scrolling
    '::-webkit-scrollbar': {
      display: 'none' // Hides scrollbar for WebKit browsers
    },
    scrollbarWidth: 'none',// Hides scrollbar for Firefox
    // padding:2
  }} elevation={0}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{fontSize:10,textTransform:"uppercase",fontWeight:"700", color:"#f5f5f5"}}>Client Payment Number</TableCell>
                        <TableCell sx={{fontSize:10,textTransform:"uppercase",fontWeight:"700", color:"#f5f5f5"}}>Amount Paid</TableCell>
                        <TableCell sx={{fontSize:10,textTransform:"uppercase",fontWeight:"700", color:"#f5f5f5"}}>Payment Status</TableCell>
                        <TableCell sx={{fontSize:10,textTransform:"uppercase",fontWeight:"700", color:"#f5f5f5"}}>Order ID</TableCell>
                        <TableCell sx={{fontSize:10,textTransform:"uppercase",fontWeight:"700", color:"#f5f5f5"}}>Created At</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {paginatedData?.length > 0 ? (
                    paginatedData.map((item, index) => (
                        <TableRow key={index}>
                        <TableCell sx={{fontSize:12, textTransform:"capitalize", fontWeight:"400", color:"#f5f5f5"}}>{item.client_payment_number}</TableCell>
                        <TableCell sx={{fontSize:12, textTransform:"capitalize", fontWeight:"400", color:"#f5f5f5"}}>{item.amount_payed}</TableCell>
                        <TableCell sx={{fontSize:12, textTransform:"capitalize", fontWeight:"400", color:"#f5f5f5"}}>{item.payment_status}</TableCell>
                        <TableCell sx={{fontSize:12, textTransform:"capitalize", fontWeight:"400", color:"#f5f5f5"}}>{item.order_id}</TableCell>
                        <TableCell sx={{fontSize:12, textTransform:"capitalize", fontWeight:"400", color:"#f5f5f5"}}>{new Date(item.created_at).toLocaleString()}</TableCell>
                        {/* Add more cells as needed */}
                        </TableRow>
                    ))
                    ) : (
                    <TableRow>
                        <Typography align="center" sx={{m:4,fontSize:12, textTransform:"capitalize", fontWeight:"400", color:"#f5f5f5", textAlign:"center", justifyContent:"center", alignItems:"center"}}>
                           No data found
                        </Typography>
                        
                        {/* <TableCell sx={{fontSize:12, textTransform:"capitalize", fontWeight:"400", color:"#f5f5f5"}} colSpan={2} align="center">
                        No data found
                        </TableCell> */}
                    </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>

        {/* Pagination */}
      <TablePagination
       sx={{
        backgroundColor:"#012d5a",
        overflow: 'auto', // Allows scrolling if needed
        '::-webkit-scrollbar': {
          display: 'none' // Hides scrollbar for WebKit browsers
        },
        scrollbarWidth: 'none', // Hides scrollbar for Firefox
        fontSize: 12, // Adjust font size
        textTransform: 'capitalize', // Capitalizes the text
        color:"#f0f0f0",
        '& .MuiTablePagination-toolbar': {
            backgroundColor:"#012d5a",
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 16px', // Adjust padding for toolbar
        },
        '& .MuiTablePagination-selectLabel': {
            backgroundColor:"#012d5a",
          fontSize:12, // Adjust font size for label
        },
        '& .MuiTablePagination-select': {
            backgroundColor:"#012d5a",
          fontSize:12, // Adjust font size for select dropdown
          color: '#f0f0f0', // Color of the select dropdown text
        },
        '& .MuiTablePagination-displayedRows': {
            backgroundColor:"#012d5a",
          fontSize: 12, // Adjust font size for displayed rows text
        },
        '& .MuiTablePagination-input': {
            backgroundColor:"#012d5a"
        },
        '& .MuiTablePagination-actions': {
            backgroundColor:"#012d5a",
          fontSize: 12, // Adjust font size for pagination actions
        },
        '& .MuiSelect-select': {
          color: '#f0f0f0', // Color of the select value text
        },
        '& .MuiMenuItem-root': {
          fontSize:12, // Font size for menu items
          color: '#f0f0f0', // Default text color for menu items
          '&:hover': {
            backgroundColor: '#f0f0f0', // Change background color on hover
            color:'#0013f3'
          },
          '&.Mui-selected': {
            backgroundColor: '#ffffff', // Change background color when selected
            color:'#001f3f3',
          }
        }
      }}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            sx: {
              backgroundColor:"#006494",
              borderRadius:"1px", // Change border radius of the menu items container
              boxShadow: 'none', // Remove box shadow of the menu items box
              '& .MuiMenuItem-root': {
                fontSize: 12, // Font size for menu items
                color: '#f0f0f0', // Default text color for menu items
                '&:hover': {
                  backgroundColor: '#DCDCDC', // Change background color on hover
                  color:'#333333'
                },
                '&.Mui-selected': {
                  backgroundColor: '#f5f5f5', // Change background color when selected
                  color:'#333333',
                }
              }
            }
          }
        }
      }}
        component="div"
        count={filteredData?.length || 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Box>
    
  )
}

export default TaskReportsTable

import React from "react";
import { useTaskReportSummary } from "../components/UseHooks"; // Import the custom hook

const Home = () => {
  const { taskReports, loading, error } = useTaskReportSummary();

  return (
    <div className="container mt-4">
      <h1 className="text-center mb-4">Task Report Summary</h1>

      {/* Show Loading State */}
      {loading && (
        <div className="text-center mt-5">Loading task reports...</div>
      )}

      {/* Show Error State */}
      {error && (
        <div className="text-center mt-5 text-danger">
          Error: {error.message || "Something went wrong!"}
        </div>
      )}

      {/* Show Task Report Cards */}
      {!loading && !error && taskReports.length === 0 ? (
        <p className="text-center">No reports available.</p>
      ) : (
        <div className="row">
          {taskReports.map((report) => (
            <div className="col-md-4 mb-4" key={report.task_id}>
              <div className="card border-primary shadow-sm">
                <div className="card-header bg-primary text-white">
                  <h5 className="card-title mb-0">
                    Task ID: {report.task_name}
                  </h5>
                </div>
                <div className="card-body">
                  <p>
                    <strong>Total Payments:</strong> {report.total_payments}
                  </p>
                  <p>
                    <strong>Total Amount:</strong> {report.total_amount || 0}
                  </p>
                  <p>
                    <strong>Paid Count:</strong> {report.paid_count}
                  </p>
                  <p>
                    <strong>Pending Count:</strong> {report.pending_count}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Home;

import React, { createContext, useEffect, useState } from 'react'

const UserAuthContext = createContext();

export function UserAuthProvider({ children }) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const checkAuth = async () => {
        try {
          const accessToken = localStorage.getItem('UserToken');
          if (accessToken) {
            setIsAuthenticated(true);
          }
        } catch {
          setIsAuthenticated(false);
        } finally {
          setLoading(false);
        }
      };
      checkAuth();
    }, []);
  
    return (
      <UserAuthContext.Provider value={{ isAuthenticated, loading }}>
        {children}
      </UserAuthContext.Provider>
    );
  }

export default UserAuthContext

import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import { ThemeProvider} from '@mui/material/styles';
import Theme from './components/Theme';
import Home from './Pages/Home';
import Tasks from './Pages/Tasks';
import Profile from './Pages/Profile';
import DivertLinks from './Pages/DivertLinks';
import Login from './Pages/Login';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import UserManangement from './Pages/UserManangement';
import { UserAuthProvider } from './context/UserAuthContext';
import UserLogin from './Pages/UserLogin';
import UserPanel from './Pages/UserPanel';
import ProtectedRoute2 from './components/ProtectedRoute2';



function App() {
  return (
    <ThemeProvider theme={Theme}>
      <AuthProvider>
        <UserAuthProvider>
          <Router>
            <Routes>
              {/* Routes without layout */}
              <Route path="/Login" element={<Login />} />
              <Route path="/Login/User" element={<UserLogin />} />
              <Route path="/user/home" element={<ProtectedRoute2 element={<UserPanel />}/>} />

              {/* Routes with layout */}
              <Route path='/' element={<ProtectedRoute element={<Layout/>} />}>
                <Route path="/" element={<ProtectedRoute element={<Home />}/>} />
                <Route path="/tasks" element={<ProtectedRoute element={<Tasks />}/>} />
                <Route path="/users-management" element={<ProtectedRoute element={<UserManangement/>}/>} />
                <Route path="/profile" element={<ProtectedRoute element={<Profile />}/>} />
                <Route path="/divert-link" element={<ProtectedRoute element = {DivertLinks} />} />
              </Route>
            </Routes>
          </Router>
        </UserAuthProvider>
        
      </AuthProvider>
    </ThemeProvider>
  
  );
}

export default App;

import { Box, Button, Container, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { loginUserNormal } from '../components/UseHooks';

const UserLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const mutation = useMutation(loginUserNormal, {
    onSuccess: (data) => {
      // Save token to localStorage or in-state
      localStorage.setItem('UserToken', data.token);
      localStorage.setItem('UserData', data);
      setIsAuthenticated(true);
      // Redirect user after successful login
      navigate('/user/home');
    },
    onError: (error) => {
      setError('Invalid username or password');
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation.mutate({ username, password });
  };

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(false);
        // setShowSuccess(false);
      }, 5000); // Message will disappear after 5 seconds

      return () => clearTimeout(timer);
    }
  }, [error]);
  return (
    <Container maxWidth="xs" sx={{ // Full viewport height to center on the screen
        overflow: 'scroll', // Allows scrolling
        '::-webkit-scrollbar': {
          display: 'none' // Hides scrollbar for WebKit browsers
        },
        scrollbarWidth: 'none', // Hides scrollbar for Firefox
        padding: 2, // Centers content horizontally within the viewport
      }}>
        <Box
         sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}>
            <Typography variant="h5" sx={{fontSize:20, fontWeight:"700", textTransform:"capitalize",  color:"#004a7c"}}>Welcome Back</Typography>
            <Typography style={{fontSize:12,color:"#555", fontWeight:"400",marginBottom:"20px",textTransform:"capitalize"}}>Please enter your credentials to continue</Typography>
            {error && (
              <Box style={{backgroundColor:"#f8d7da",padding:"15px", borderRadius:"2px",border:"1px solid #f5c6cb", marginTop:"10px", marginBottom:"10px"}}>
                <Typography style={{ color:'#721c24', fontSize:"12px"}}>{error}</Typography>
              </Box>
            )}
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <TextField
                label="Username"
                variant="outlined"
                fullWidth
                margin="normal"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                sx={{
                  mb:2,
                  '& .MuiInputLabel-root': {
                  color: '#999', // Label color when not focused
                  fontSize:"small",
                  textTransform:"capitalize",
                  '&.Mui-focused': {
                      color: '#001f3f', // Label color when focused
                  },
                  },
                  '& .MuiOutlinedInput-root': {
                      borderRadius: '2px', // Border radius
                  '& fieldset': {
                      borderColor: '#ddd', // Border color when not focused
                  },
                  '&:hover fieldset': {
                      borderColor: '#001f3f', // Border color on hover
                  },
                  '&.Mui-focused fieldset': {
                      borderColor: '#001f3f', // Border color when focused
                  },
                  },
              }}
              InputProps={{
                  sx: {
                      fontSize:12,
                      textTransform:"capitalize",
                      color:"#333",
                      '& input::placeholder': {
                      fontSize: 12, // Change placeholder font size here
                      color: '#555',
                      textTransform:"capitalize" // Optionally change color
                      },
                  },
              }}
              />
              <TextField
                label="Password"
                variant="outlined"
                fullWidth
                margin="normal"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                sx={{
                  mb:2,
                  '& .MuiInputLabel-root': {
                  color: '#999', // Label color when not focused
                  fontSize:"small",
                  textTransform:"capitalize",
                  '&.Mui-focused': {
                      color: '#001f3f', // Label color when focused
                  },
                  },
                  '& .MuiOutlinedInput-root': {
                      borderRadius: '2px', // Border radius
                  '& fieldset': {
                      borderColor: '#ddd', // Border color when not focused
                  },
                  '&:hover fieldset': {
                      borderColor: '#001f3f', // Border color on hover
                  },
                  '&.Mui-focused fieldset': {
                      borderColor: '#001f3f', // Border color when focused
                  },
                  },
              }}
              InputProps={{
                  sx: {
                      fontSize:12,
                      textTransform:"capitalize",
                      color:"#333",
                      '& input::placeholder': {
                      fontSize: 12, // Change placeholder font size here
                      color: '#555',
                      textTransform:"capitalize" // Optionally change color
                      },
                  },
              }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={mutation.isLoading}
                sx={{
                  marginTop: '1rem',
                  backgroundColor:"#006494",
                  borderRadius:"1px",
                  padding:1,
                  boxShadow:0,
                  '&:hover':{
                    backgroundColor:"#004a7c",
                    boxShadow:0,
                  }
              }}
              >
                {mutation.isLoading ? 'Logging in...' : 'Log In'}
              </Button>
            </form>
      </Box>
      
      
    </Container>
  )
}

export default UserLogin

import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom';
import UserAuthContext from '../context/UserAuthContext';

function ProtectedRoute2({ element }) {
    const { isAuthenticated, loading } = useContext(UserAuthContext);
  
    if (loading) {
      return <div>Loading...</div>; // You can replace this with a loading spinner
    }
  
    return isAuthenticated ? element : <Navigate to="/Login/User" />;
  }

export default ProtectedRoute2

import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import { fetchProtectedData } from "./UseHooks";
import { useQuery } from "react-query";

const drawerWidth = 250;

// Custom styled ListItem to apply active and hover styles
const SidebarLink = styled(ListItem)(({ theme, active }) => ({
  padding: theme.spacing(1, 2),
  backgroundColor: active ? theme?.palette?.action?.selected : "inherit",
  color: active ? theme.palette.primary.main : "inherit",
  "&:hover": {
    backgroundColor: theme?.palette?.action?.hover || "#f5f5f5",
  },
}));

const Layout = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const drawerContent = (
    <List sx={{ mt: { xs: 7, sm: 8 } }}>
      {" "}
      {/* Offset to avoid overlap with AppBar */}
      <SidebarLink
        button
        component={Link}
        to="/"
        active={location.pathname === "/"}
      >
        <HomeIcon style={{ marginRight: "10px", color: "#ffffff" }} />
        <ListItemText
          style={{ color: "#ffffff" }}
          primary={
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "medium",
                textTransform: "uppercase",
              }}
            >
              Home
            </Typography>
          }
        />
      </SidebarLink>
      <SidebarLink
        button
        component={Link}
        to="/tasks"
        active={location.pathname === "/tasks"}
      >
        <AssignmentIcon style={{ marginRight: "10px", color: "#ffffff" }} />
        <ListItemText
          style={{ color: "#ffffff" }}
          primary={
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "medium",
                textTransform: "uppercase",
              }}
            >
              Tasks
            </Typography>
          }
        />
      </SidebarLink>
      <SidebarLink
        button
        component={Link}
        to="/profile"
        active={location.pathname === "/profile"}
      >
        <AccountCircleIcon style={{ marginRight: "10px", color: "#ffffff" }} />
        <ListItemText
          style={{ color: "#ffffff" }}
          primary={
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "medium",
                textTransform: "uppercase",
              }}
            >
              Profile
            </Typography>
          }
        />
      </SidebarLink>
      <SidebarLink
        button
        component={Link}
        to="/divert-link"
        active={location.pathname === "/divert-link"}
      >
        <InsertLinkIcon style={{ marginRight: "10px", color: "#ffffff" }} />
        <ListItemText
          style={{ color: "#ffffff" }}
          primary={
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "medium",
                textTransform: "uppercase",
              }}
            >
              Divert link
            </Typography>
          }
        />
      </SidebarLink>
    </List>
  );

  return (
    <Box sx={{ display: "flex", backgroundColor: "#012d5a" }}>
      {/* AppBar/Header Navbar */}
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          boxShadow: "none",
          backgroundColor: "#001F3F",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            onClick={toggleDrawer}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              fontSize: "1.625rem",
              fontWeight: "700",
              textTransform: "uppercase",
            }}
          >
            AssignHub
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Sidebar for desktop view */}
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#012d5a",
            // mt: '64px', // Offset for AppBar height
          },
          display: { xs: "none", md: "block" },
        }}
        open
      >
        {drawerContent}
      </Drawer>

      {/* Sidebar for mobile view */}
      <Drawer
        variant="temporary"
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#012d5a",
          },
          display: { xs: "block", md: "none" },
        }}
      >
        {drawerContent}
      </Drawer>

      {/* Main content area */}
      <Box
        component="main"
        sx={{
          minHeight: "100vh",
          p: 3,
          //   ml: { md: drawerWidth },
          mt: { xs: "56px", sm: "64px" }, // Adjust for AppBar height
          overflow: "scroll", // Allows scrolling
          "::-webkit-scrollbar": {
            display: "none", // Hides scrollbar for WebKit browsers
          },
          scrollbarWidth: "none", // Hides scrollbar for Firefox
          backgroundColor: "#012d5a",
          flex: 1,
        }}
      >
        {/* <Toolbar /> */}
        <main>
          <Outlet />
        </main>
      </Box>
    </Box>
  );
};

export default Layout;

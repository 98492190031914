import React from 'react'
import {createTheme } from '@mui/material/styles';

const Theme =createTheme({
    palette: {
      primary: {
        main: '#001F3F', // Set a default primary color
      },
      secondary: {
        main: '#F5A623', // Set a default secondary color
      },
      action: {
        default:"#013a73",
        selected: '#006494', // Ensure a color for selected state
        hover: '#004a7c', // Hover state color
      },
    },
   
  });

export default Theme

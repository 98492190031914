import { AppBar, Box,Dialog,DialogContent,DialogTitle,Grid,IconButton,InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Toolbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { fetchAssignedTasks } from '../components/UseHooks';
import SearchIcon from '@mui/icons-material/Search';

import InfoIcon from '@mui/icons-material/Info';
import { Cancel } from '@mui/icons-material';
import TaskReportsTable from '../components/TaskReportsTable';

const UserPanel = () => {
    // Use React Query to fetch tasks
  const { data, isLoading, error, refetch } = useQuery(["assignedTasks"], fetchAssignedTasks);
  const [tasks, setTasks] = useState([]);
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [taskId, setTaskId] = useState('');

  useEffect(() => {
    if (data) {
      setTasks(data); // Update tasks with fetched data
    }
  }, [data]);

  const handleReport = (taskId) =>{
    setTaskId(taskId)
    setOpenReportDialog(true)
  }

 const handleReportDialogClose = () => {
   setOpenReportDialog(false);
   setTaskId(null);
 }

  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');

  // Handle sorting functionality
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Sort data based on the current order
   // Ensure tasks is an array before sorting
const sortedTasks = Array.isArray(tasks)
? tasks.sort((a, b) => {
    if (orderBy === 'name') {
      return order === 'asc'
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    }
    return 0; // For simplicity, we only sort by name here
  })
: [];

  // Handle search filter
  const filteredTasks = sortedTasks?.filter((task) =>
    task.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    task.description.toLowerCase().includes(searchQuery.toLowerCase())
    // task.link.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination handler
  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => setRowsPerPage(parseInt(event.target.value, 10));

  // Action buttons for each row (Edit, Delete, JS Code Snippet)
  

  // const handleDelete = (taskId) => {
  //   setTasks(tasks.filter((task) => task.id !== taskId));
  // };

  useEffect(() => {
    const interval = setInterval(() => {
      refetch(); // Refetch data every 5 seconds (5000 ms)
    }, 5000);

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [refetch]);

  if (isLoading) return (
        <Box sx={{flex:1, alignItems:"center", justifyContent:"center"}}>
            <Typography style={{fontSize:"14px", fontWeight:"500"}}>Loading.........</Typography>
        </Box>
        );

  if (error) return (
    <Box style={{backgroundColor:"#f8d7da",padding:"15px", borderRadius:"2px",border:"1px solid #f5c6cb", marginTop:"10px", marginBottom:"10px", maxWidth:"500px"}}>
        <Typography style={{ color:'#721c24', fontSize:"12px"}}>Failed to fetch tasks: {error.message}</Typography>
    </Box>
  )

  return (
    <Box component='main' sx={{
        overflow: 'scroll', // Allows scrolling
        '::-webkit-scrollbar': {
          display: 'none' // Hides scrollbar for WebKit browsers
        },
        scrollbarWidth: 'none', // Hides scrollbar for Firefox
        marginTop:2,
        padding:2
    }}>
        <AppBar elevation={0} position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, boxShadow: "none", backgroundColor: '#001F3F' }}>
            <Toolbar>
            {/* <IconButton color="inherit" edge="start" onClick={toggleDrawer} sx={{ mr: 2, display: { md: 'none' } }}>
                <MenuIcon />
            </IconButton> */}
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: "1.625rem", fontWeight: "700", textTransform: "uppercase" }}>
                AssignHub
            </Typography>
            </Toolbar>
        </AppBar>

        <Box sx={{mb:4, mt:6, 
        padding:2}}>
            <Typography style={{fontSize:16, fontWeight:"700",textTransform:"uppercase"}} variant="h4" component="h1" gutterBottom>
                Assigned Tasks
            </Typography>
            <Typography style={{fontSize:12,color:"#555", fontWeight:"400",textTransform:"capitalize"}} variant="subtitle1" color="textSecondary">
                View all tasks assigned to you.
            </Typography>
        </Box>

        <Toolbar sx={{mb:2}}>
           <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                   <TextField
                        label="Search"
                        variant="outlined"
                        fullWidth
                        placeholder="Search ... "
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        
                        sx={{
                            mb:2,
                            '& .MuiInputLabel-root': {
                            color: '#999', // Label color when not focused
                            fontSize:"small",
                            textTransform:"capitalize",
                            '&.Mui-focused': {
                                color: '#f5f5f5', // Label color when focused
                            },
                            },
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '2px', // Border radius
                            '& fieldset': {
                                borderColor: '#ddd', // Border color when not focused
                            },
                            '&:hover fieldset': {
                                borderColor: '#f5f5f5', // Border color on hover
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#f5f5f5', // Border color when focused
                            },
                            },
                        }}
                        InputProps={{
                            sx: {
                                fontSize:12,
                                textTransform:"capitalize",
                                color:"#dcdcdc",
                                '& input::placeholder': {
                                fontSize: 12, // Change placeholder font size here
                                color: '#555',
                                textTransform:"capitalize" // Optionally change color
                                },
                            },
                        startAdornment: (
                            <InputAdornment position="start">
                            <SearchIcon />
                            </InputAdornment>
                        ),
                        }}
                    />
                </Grid>
            </Grid>
        </Toolbar>


        <Box component='main' sx={{
        overflow: 'scroll', // Allows scrolling
        '::-webkit-scrollbar': {
          display: 'none' // Hides scrollbar for WebKit browsers
        },
        scrollbarWidth: 'none', // Hides scrollbar for Firefox
        marginTop:2,
        padding:2
    }}>
            <TableContainer>
                <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell sx={{fontSize:10,textTransform:"uppercase",fontWeight:"700", }}>
                            <TableSortLabel
                            active={orderBy === 'name'}
                            direction={orderBy === 'name' ? order : 'asc'}
                            onClick={() => handleRequestSort('name')}
                            // style={{color:"#f5f5f5"}}
                            >
                            Task Name
                            </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{fontSize:10,textTransform:"uppercase",fontWeight:"700", }}>Description</TableCell>
                        <TableCell sx={{fontSize:10,textTransform:"uppercase",fontWeight:"700", }}>Amount</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredTasks
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((task) => (
                        <TableRow key={task.id}>
                            <TableCell sx={{fontSize:12, textTransform:"capitalize", fontWeight:"400",}}>{task.name}</TableCell>
                            <TableCell sx={{fontSize:12, textTransform:"capitalize", fontWeight:"400",}}>{task.description}</TableCell>
                            
                            <TableCell sx={{fontSize:12, textTransform:"capitalize", fontWeight:"400", }}>{task.amount}</TableCell>
                            <TableCell> 
                                <IconButton 
                                style={{
                                borderRadius:"2px",
                                    color:"#012d5a",
                                    backgroundColor: '#E0E0E0', // Light red background
                                    '&:hover': {
                                    backgroundColor: '#B3B3B3', // Darker red on hover
                                    },
                                }}
                                onClick={() => handleReport(task.id)}>
                                <InfoIcon/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Pagination */}
            <TablePagination
            
            sx={{
                backgroundColor:"#012d5a",
                overflow: 'auto', // Allows scrolling if needed
                '::-webkit-scrollbar': {
                display: 'none' // Hides scrollbar for WebKit browsers
                },
                scrollbarWidth: 'none', // Hides scrollbar for Firefox
                fontSize: 12, // Adjust font size
                textTransform: 'capitalize', // Capitalizes the text
                color:"#f0f0f0",
                '& .MuiTablePagination-toolbar': {
                    backgroundColor:"#012d5a",
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 16px', // Adjust padding for toolbar
                },
                '& .MuiTablePagination-selectLabel': {
                    backgroundColor:"#012d5a",
                fontSize:12, // Adjust font size for label
                },
                '& .MuiTablePagination-select': {
                    backgroundColor:"#012d5a",
                fontSize:12, // Adjust font size for select dropdown
                color: '#f0f0f0', // Color of the select dropdown text
                },
                '& .MuiTablePagination-displayedRows': {
                    backgroundColor:"#012d5a",
                fontSize: 12, // Adjust font size for displayed rows text
                },
                '& .MuiTablePagination-input': {
                    backgroundColor:"#012d5a"
                },
                '& .MuiTablePagination-actions': {
                    backgroundColor:"#012d5a",
                fontSize: 12, // Adjust font size for pagination actions
                },
                '& .MuiSelect-select': {
                color: '#f0f0f0', // Color of the select value text
                },
                '& .MuiMenuItem-root': {
                fontSize:12, // Font size for menu items
                color: '#f0f0f0', // Default text color for menu items
                '&:hover': {
                    backgroundColor: '#f0f0f0', // Change background color on hover
                    color:'#0013f3'
                },
                '&.Mui-selected': {
                    backgroundColor: '#ffffff', // Change background color when selected
                    color:'#001f3f3',
                }
                }
            }}
            SelectProps={{
                MenuProps: {
                PaperProps: {
                    sx: {
                    backgroundColor:"#006494",
                    borderRadius:"1px", // Change border radius of the menu items container
                    boxShadow: 'none', // Remove box shadow of the menu items box
                    '& .MuiMenuItem-root': {
                        fontSize: 12, // Font size for menu items
                        color: '#f0f0f0', // Default text color for menu items
                        '&:hover': {
                        backgroundColor: '#DCDCDC', // Change background color on hover
                        color:'#333333'
                        },
                        '&.Mui-selected': {
                        backgroundColor: '#f5f5f5', // Change background color when selected
                        color:'#333333',
                        }
                    }
                    }
                }
                }
            }}
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredTasks.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
        <Dialog sx={{ "& .MuiPaper-root": { borderRadius: "1px"} }} open={openReportDialog} onClose={handleReportDialogClose}>
        <DialogTitle sx={{display:"flex", backgroundColor: '#006494',flexDirection:"row", alignItems:"center", justifyContent:"space-between"}}>
            <Box sx={{display:"flex",flexDirection:"row", alignItems:"center", gap:1}}>
            {/* <JavascriptIcon sx={{fontSize:"35px", backgroundColor:"#f0f0f0", borderRadius:"2px"}}/> */}
            <Typography sx={{fontSize:20, fontWeight:700,color:"#f0f0f0", textTransform:"capitalize"}}>
                TASK INFOS
            </Typography>
            </Box>
            <IconButton onClick={handleReportDialogClose}>
            <Cancel sx={{color:"#f0f0f0"}}/>
            </IconButton>
            
        </DialogTitle>
        <DialogContent sx={{backgroundColor:"#001f3f",
            overflow: 'scroll', // Allows scrolling
            '::-webkit-scrollbar': {
            display: 'none' // Hides scrollbar for WebKit browsers
            },
            scrollbarWidth: 'none', // Hides scrollbar for Firefox
        }}>
            
            <TaskReportsTable fullWidth taskId={taskId}/>

        </DialogContent>
        
        </Dialog>
    </Box>
  )
}

export default UserPanel

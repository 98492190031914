// hooks/useTasks.js
import axios from "axios";
import { useQuery } from "react-query";
import { useState, useEffect } from "react";
// Define the fetch function to get the tasks

const fetchTasks = async () => {
  const token = localStorage.getItem("token"); // Get the token from localStorage or where it's stored

  const response = await fetch(
    "https://publishers.skano.agency/tasks/api/v1/get-tasks/",
    {
      headers: {
        Authorization: `Token ${token}`, // Include the token in the header
        "Content-Type": "application/json",
      },
    },
  );

  if (!response.ok) {
    return null;
  }

  return response.json();
};

const useTasks = () => {
  return useQuery("tasks", fetchTasks);
};

export default useTasks;

export const deleteTask = async (taskId) => {
  const token = localStorage.getItem("token");
  const response = await axios.delete(
    `https://publishers.skano.agency/tasks/api/v1/${taskId}/delete/`,
    {
      headers: {
        Authorization: `Token ${token}`, // Include the token in the header
        "Content-Type": "application/json",
      },
    },
  );
  return response.data;
};

// Axios instance for API requests
const axiosInstance = axios.create({
  baseURL: "https://publishers.skano.agency", // Adjust to your API URL
});

export const loginUser = async (userData) => {
  const response = await axiosInstance.post("auth/api/v1/login/", userData);
  return response.data; // The response contains the token
};

export const loginUserNormal = async (userData) => {
  const response = await axiosInstance.post(
    "auth/api/v1/user/login/",
    userData,
  );
  return response.data; // The response contains the token
};

export const fetchUsers = async () => {
  const response = await axios.get(
    "https://publishers.skano.agency/auth/api/v1/users/",
    {
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`, // Assuming you're storing the JWT token in localStorage
      },
    },
  );
  return response.data;
};

export const deleteUser = async (userId) => {
  const token = localStorage.getItem("token");
  const response = await axios.delete(
    `https://publishers.skano.agency/auth/api/v1/user/${userId}/delete/`,
    {
      headers: {
        Authorization: `Token ${token}`, // Include the token in the header
        "Content-Type": "application/json",
      },
    },
  );
  return response.data;
};

export const fetchAssignedTasks = async () => {
  const response = await axios.get(
    "https://publishers.skano.agency/tasks/api/v1/assigned/",
    {
      headers: {
        Authorization: `Token ${localStorage.getItem("UserToken")}`, // Use token stored in localStorage
      },
    },
  );
  return response.data;
};

export const fetchTaskReports = async (taskId) => {
  const response = await axios.get(
    `https://publishers.skano.agency/tasks/api/v1/task-reports/${taskId}/`,
    {
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`, // Use token stored in localStorage
      },
    },
  );
  return response.data;
};

export const useTaskReportSummary = () => {
  const [taskReports, setTaskReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTaskReportSummary = async () => {
      setLoading(true); // Set loading to true while fetching
      try {
        const response = await axios.get(
          "https://publishers.skano.agency/tasks/api/v1/task-report-summary/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("token")}`, // Include token for authorization
            },
          },
        );
        setTaskReports(response.data); // Set the task report data
      } catch (err) {
        setError(err); // Capture and set the error
        console.error(
          "Failed to fetch task report summary:",
          err.response?.data || err.message,
        );
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchTaskReportSummary(); // Fetch data on component mount
  }, []);

  return { taskReports, loading, error }; // Return state to the consuming component
};

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  IconButton,
  InputAdornment,
  Typography,
  Toolbar,
  Grid,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Cancel,
} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from "@mui/icons-material/Info";
import AddIcon from "@mui/icons-material/Add";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import JavascriptIcon from "@mui/icons-material/Javascript";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import useTasks, { deleteTask } from "../components/UseHooks";
import TaskReportsTable from "../components/TaskReportsTable";

const Tasks = () => {
  const { data, error, isLoading, refetch } = useTasks();
  const [tasks, setTasks] = useState([]);
  const [taskId, setTaskId] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openSnippestDialog, setOpenSnippestDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [newTask, setNewTask] = useState({
    name: "",
    amount: "",
    description: "",
    redirect_link: "",
  });
  const [editTask, setEditTask] = useState({
    name: "",
    amount: "",
    description: "",
    link: "",
    redirect_link: "",
    payment_placeholder: "",
    pay_button_details: "",
    success_message: "",
    loading_message: "",
    session_expire_message: "",
    invalid_number_message: "",
  });
  const [selectedTask, setSelectedTask] = useState(null);
  const [id, setId] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError1, setIsError1] = useState(false);
  const [isSucess1, setIsSuccess1] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [error1, setError1] = useState(false);
  const queryClient = useQueryClient();
  const token = localStorage.getItem("token");

  // Mutation for deleting a task
  const deleteTaskMutation = useMutation(deleteTask, {
    onSuccess: () => {
      // Invalidate the tasks query to refresh the task list after deletion
      queryClient.invalidateQueries("tasks");
      setIsSuccess(true);
    },
    onError: (error) => {
      setIsError(true);
    },
  });

  // Handler for delete button
  const handleDelete = (taskId) => {
    deleteTaskMutation.mutate(taskId);
  };

  useEffect(() => {
    if (data) {
      setTasks(data); // Update tasks with fetched data
    }
  }, [data]);

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");

  // Handle opening/closing the dialog
  const handleDialogOpen = () => setOpenDialog(true);
  const handleDialogClose = () => setOpenDialog(false);

  // Handle opening/closing the dialog
  const handleSnippestDialogOpen = (task) => {
    setSelectedTask(task);
    setOpenSnippestDialog(true);
  };
  const handleSnippestDialogClose = () => {
    setOpenSnippestDialog(false);
    setSelectedTask(null);
  };
  const handleEditDialogOpen = (task) => {
    setId(task.id);
    setOpenEditDialog(true);
  };
  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
    setId(null);
  };

  // Handle adding new task

  const mutation = useMutation((data) =>
    axios.post(
      "https://publishers.skano.agency/tasks/api/v1/create-task/",
      data,
      {
        headers: {
          Authorization: `Token ${token}`, // Include the token in the header
          "Content-Type": "application/json",
        },
      },
    ),
  );
  const handleAddTask = () => {
    mutation.mutate(newTask, {
      onSuccess: (response) => {
        setOpenDialog(false);
        setSuccess(true); // Reset copy status when a new link is generated
      },
      onError: (error) => {
        setOpenDialog(false);
        setError1(true);
      },
    });
  };

  const handleReport = (taskId) => {
    setTaskId(taskId);
    setOpenReportDialog(true);
  };

  const handleReportDialogClose = () => {
    setOpenReportDialog(false);
    setTaskId(null);
  };

  const editMutation = useMutation(({ id, data }) =>
    axios.put(
      `https://publishers.skano.agency/tasks/api/v1/edit-task/${id}/`,
      data,
      {
        headers: {
          Authorization: `Token ${token}`, // Include the token in the header
          "Content-Type": "application/json",
        },
      },
    ),
  );
  const handleEditTask = () => {
    editMutation.mutate(
      { id, data: editTask },
      {
        onSuccess: (response) => {
          setOpenEditDialog(false);
          setIsSuccess1(true); // Reset copy status when a new link is generated
        },
        onError: (error) => {
          setOpenEditDialog(false);
          setIsError1(true);
        },
      },
    );
  };
  const handleCopyToClipboard = (link) => {
    const scriptTag = `
      <!-- Pixel Code - https://payment.mishe.online/-->

      <script src="${link}"></script>

      <!-- END Pixel Code - https://payment.mishe.online/-->
      `;
    navigator.clipboard.writeText(scriptTag).then(() => {});
  };

  // Handle sorting functionality
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Sort data based on the current order
  // Ensure tasks is an array before sorting
  const sortedTasks = Array.isArray(tasks)
    ? tasks.sort((a, b) => {
        if (orderBy === "name") {
          return order === "asc"
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name);
        }
        return 0; // For simplicity, we only sort by name here
      })
    : [];

  // Handle search filter
  const filteredTasks = sortedTasks?.filter(
    (task) =>
      task.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      task.description.toLowerCase().includes(searchQuery.toLowerCase()),
    // task.link.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination handler
  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) =>
    setRowsPerPage(parseInt(event.target.value, 10));

  // Action buttons for each row (Edit, Delete, JS Code Snippet)

  // const handleDelete = (taskId) => {
  //   setTasks(tasks.filter((task) => task.id !== taskId));
  // };

  useEffect(() => {
    const interval = setInterval(() => {
      refetch(); // Refetch data every 5 seconds (5000 ms)
    }, 5000);

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [refetch]);

  useEffect(() => {
    if (isError || isSuccess || isError1 || error1 || isSucess1 || sucess) {
      const timer = setTimeout(() => {
        setIsError(false);
        setIsSuccess(false);
        setError1(false);
        setIsError1(false);
        setIsSuccess1(false);
        setSuccess(false);
      }, 7000); // Message will disappear after 5 seconds

      return () => clearTimeout(timer);
    }
  }, [isError, isSuccess, isError1, error1, isSucess1, sucess]);

  if (isLoading) {
    return (
      <Box sx={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <Typography
          style={{ fontSize: "14px", fontWeight: "500", color: "#ffff" }}
        >
          Loading.........
        </Typography>
      </Box>
    );
  }
  return (
    <Box
      component="main"
      sx={{
        overflow: "scroll", // Allows scrolling
        "::-webkit-scrollbar": {
          display: "none", // Hides scrollbar for WebKit browsers
        },
        scrollbarWidth: "none", // Hides scrollbar for Firefox
        marginTop: 2,
        padding: 2,
      }}
    >
      <Box sx={{ mb: 4 }}>
        <Typography
          style={{
            fontSize: 16,
            color: "#f5f5f5",
            fontWeight: "700",
            textTransform: "uppercase",
          }}
          variant="h4"
          component="h1"
          gutterBottom
        >
          Task Management
        </Typography>
        <Typography
          style={{
            fontSize: 12,
            color: "#f0f0f0",
            fontWeight: "400",
            textTransform: "capitalize",
          }}
          variant="subtitle1"
          color="textSecondary"
        >
          View, add, and manage tasks effortlessly. Track progress, assign
          users, and maintain an organized workflow.
        </Typography>
      </Box>
      {isSuccess && (
        <Box
          style={{
            backgroundColor: "#d4edda",
            padding: "15px",
            borderRadius: 2,
            border: "1 solid #c3e6cb",
            marginTop: "10px",
            marginBottom: "10px",
            maxWidth: "500px",
          }}
        >
          <Typography style={{ color: "#155724", fontSize: "12px" }}>
            Task Deleted: The Task has been successfully removed.
          </Typography>
        </Box>
      )}
      {isSucess1 && (
        <Box
          style={{
            backgroundColor: "#d4edda",
            padding: "15px",
            borderRadius: "2px",
            border: "1 solid #c3e6cb",
            marginTop: "10px",
            marginBottom: "10px",
            maxWidth: "500px",
          }}
        >
          <Typography style={{ color: "#155724", fontSize: "12px" }}>
            Changes Saved: Your edits have been successfully updated.
          </Typography>
        </Box>
      )}
      {sucess && (
        <Box
          style={{
            backgroundColor: "#d4edda",
            padding: "15px",
            borderRadius: "2px",
            border: "1 solid #c3e6cb",
            marginTop: "10px",
            marginBottom: "10px",
            maxWidth: "500px",
          }}
        >
          <Typography style={{ color: "#155724", fontSize: "12px" }}>
            Task Added: The new Task has been successfully created.
          </Typography>
        </Box>
      )}

      {isError && (
        <Box
          style={{
            backgroundColor: "#f8d7da",
            padding: "15px",
            borderRadius: "2px",
            border: "1px solid #f5c6cb",
            marginTop: "10px",
            marginBottom: "10px",
            maxWidth: "500px",
          }}
        >
          <Typography style={{ color: "#721c24", fontSize: "12px" }}>
            Delete Failed: An error occurred while trying to delete the task.
          </Typography>
        </Box>
      )}
      {isError1 && (
        <Box
          style={{
            backgroundColor: "#f8d7da",
            padding: "15px",
            borderRadius: "2px",
            border: "1px solid #f5c6cb",
            marginTop: "10px",
            marginBottom: "10px",
            maxWidth: "500px",
          }}
        >
          <Typography style={{ color: "#721c24", fontSize: "12px" }}>
            Edit Failed: An error occurred while trying to save your changes.
          </Typography>
        </Box>
      )}
      {error && (
        <Box
          style={{
            backgroundColor: "#f8d7da",
            padding: "15px",
            borderRadius: "2px",
            border: "1px solid #f5c6cb",
            marginTop: "10px",
            marginBottom: "10px",
            maxWidth: "500px",
          }}
        >
          <Typography style={{ color: "#721c24", fontSize: "12px" }}>
            Add Failed: An error occurred while trying to add the new item.
          </Typography>
        </Box>
      )}

      <Box sx={{ mb: 4 }}>
        <Button
          elevation={0}
          disableElevation
          startIcon={<AddIcon />}
          sx={{
            color: "#ffffff",
            backgroundColor: "#006494",
            borderRadius: "1px",
            padding: 1,
            boxShadow: 0,
            "&:hover": {
              backgroundColor: "#004a7c",
            },
          }}
          onClick={handleDialogOpen}
        >
          <Typography
            sx={{ fontSize: 12, fontWeight: "500", color: "#ffffff" }}
          >
            create new task
          </Typography>
        </Button>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            textTransform: "uppercase",
            color: "#ffffff",
          }}
        >
          Total Tasks: {tasks?.length}
        </Typography>
      </Box>

      <Toolbar sx={{ mb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <TextField
              label="Search"
              variant="outlined"
              fullWidth
              placeholder="Search ... "
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{
                mb: 2,
                "& .MuiInputLabel-root": {
                  color: "#999", // Label color when not focused
                  fontSize: "small",
                  textTransform: "capitalize",
                  "&.Mui-focused": {
                    color: "#f5f5f5", // Label color when focused
                  },
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "2px", // Border radius
                  "& fieldset": {
                    borderColor: "#ddd", // Border color when not focused
                  },
                  "&:hover fieldset": {
                    borderColor: "#f5f5f5", // Border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#f5f5f5", // Border color when focused
                  },
                },
              }}
              InputProps={{
                sx: {
                  fontSize: 12,
                  textTransform: "capitalize",
                  color: "#dcdcdc",
                  "& input::placeholder": {
                    fontSize: 12, // Change placeholder font size here
                    color: "#555",
                    textTransform: "capitalize", // Optionally change color
                  },
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Toolbar>

      <Box elevation={0} sx={{ mb: 2 }}>
        <TableContainer
          sx={{
            backgroundColor: "#012d5a", // Keeps the table within the width of its container
            overflow: "scroll", // Allows scrolling
            "::-webkit-scrollbar": {
              display: "none", // Hides scrollbar for WebKit browsers
            },
            scrollbarWidth: "none", // Hides scrollbar for Firefox
            // padding:2
          }}
          elevation={0}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: 10,
                    textTransform: "uppercase",
                    fontWeight: "700",
                    color: "#f5f5f5",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "name"}
                    direction={orderBy === "name" ? order : "asc"}
                    onClick={() => handleRequestSort("name")}
                    style={{ color: "#f5f5f5" }}
                  >
                    Task Name
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: 10,
                    textTransform: "uppercase",
                    fontWeight: "700",
                    color: "#f5f5f5",
                  }}
                >
                  description
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: 10,
                    textTransform: "uppercase",
                    fontWeight: "700",
                    color: "#f5f5f5",
                  }}
                >
                  amount
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: 10,
                    textTransform: "uppercase",
                    fontWeight: "700",
                    color: "#f5f5f5",
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTasks
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((task) => (
                  <TableRow key={task.id}>
                    <TableCell
                      sx={{
                        fontSize: 12,
                        textTransform: "capitalize",
                        fontWeight: "400",
                        color: "#f5f5f5",
                      }}
                    >
                      {task.name}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: 12,
                        textTransform: "capitalize",
                        fontWeight: "400",
                        color: "#f5f5f5",
                      }}
                    >
                      {task.description}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: 12,
                        textTransform: "capitalize",
                        fontWeight: "400",
                        color: "#f5f5f5",
                      }}
                    >
                      {task.amount}
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          padding: "auto",
                        }}
                      >
                        <IconButton
                          style={{
                            borderRadius: "2px",
                            backgroundColor: "#1976D2", // Blue background
                            color: "#ffffff",
                            "&:hover": {
                              backgroundColor: "#1565C0", // Darker blue on hover
                            },
                          }}
                          onClick={() => handleSnippestDialogOpen(task)}
                          sx={{ mr: 1 }}
                        >
                          <JavascriptIcon />
                        </IconButton>
                        <IconButton
                          style={{
                            borderRadius: "2px",
                            color: "#ffffff",
                            backgroundColor: "#4CAF50", // Light green background
                            "&:hover": {
                              backgroundColor: "#388E3C", // Darker green on hover
                            },
                          }}
                          onClick={() => handleEditDialogOpen(task)}
                          sx={{ mr: 1 }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          style={{
                            borderRadius: "2px",
                            color: "#ffffff",
                            backgroundColor: "#FF4C4C", // Light red background
                            "&:hover": {
                              backgroundColor: "#FF0000", // Darker red on hover
                            },
                          }}
                          onClick={() => handleDelete(task.id)}
                          sx={{ mr: 1 }}
                        >
                          <DeleteIcon />
                        </IconButton>
                        <IconButton
                          style={{
                            borderRadius: "2px",
                            color: "#012d5a",
                            backgroundColor: "#E0E0E0", // Light red background
                            "&:hover": {
                              backgroundColor: "#B3B3B3", // Darker red on hover
                            },
                          }}
                          onClick={() => handleReport(task.id)}
                        >
                          <InfoIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        <TablePagination
          sx={{
            backgroundColor: "#012d5a",
            overflow: "auto", // Allows scrolling if needed
            "::-webkit-scrollbar": {
              display: "none", // Hides scrollbar for WebKit browsers
            },
            scrollbarWidth: "none", // Hides scrollbar for Firefox
            fontSize: 12, // Adjust font size
            textTransform: "capitalize", // Capitalizes the text
            color: "#f0f0f0",
            "& .MuiTablePagination-toolbar": {
              backgroundColor: "#012d5a",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 16px", // Adjust padding for toolbar
            },
            "& .MuiTablePagination-selectLabel": {
              backgroundColor: "#012d5a",
              fontSize: 12, // Adjust font size for label
            },
            "& .MuiTablePagination-select": {
              backgroundColor: "#012d5a",
              fontSize: 12, // Adjust font size for select dropdown
              color: "#f0f0f0", // Color of the select dropdown text
            },
            "& .MuiTablePagination-displayedRows": {
              backgroundColor: "#012d5a",
              fontSize: 12, // Adjust font size for displayed rows text
            },
            "& .MuiTablePagination-input": {
              backgroundColor: "#012d5a",
            },
            "& .MuiTablePagination-actions": {
              backgroundColor: "#012d5a",
              fontSize: 12, // Adjust font size for pagination actions
            },
            "& .MuiSelect-select": {
              color: "#f0f0f0", // Color of the select value text
            },
            "& .MuiMenuItem-root": {
              fontSize: 12, // Font size for menu items
              color: "#f0f0f0", // Default text color for menu items
              "&:hover": {
                backgroundColor: "#f0f0f0", // Change background color on hover
                color: "#0013f3",
              },
              "&.Mui-selected": {
                backgroundColor: "#ffffff", // Change background color when selected
                color: "#001f3f3",
              },
            },
          }}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: "#006494",
                  borderRadius: "1px", // Change border radius of the menu items container
                  boxShadow: "none", // Remove box shadow of the menu items box
                  "& .MuiMenuItem-root": {
                    fontSize: 12, // Font size for menu items
                    color: "#f0f0f0", // Default text color for menu items
                    "&:hover": {
                      backgroundColor: "#DCDCDC", // Change background color on hover
                      color: "#333333",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "#f5f5f5", // Change background color when selected
                      color: "#333333",
                    },
                  },
                },
              },
            },
          }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredTasks.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      <Dialog
        sx={{ "& .MuiPaper-root": { borderRadius: "1px" } }}
        open={openReportDialog}
        onClose={handleReportDialogClose}
      >
        <DialogTitle
          sx={{
            display: "flex",
            backgroundColor: "#006494",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            {/* <JavascriptIcon sx={{fontSize:"35px", backgroundColor:"#f0f0f0", borderRadius:"2px"}}/> */}
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 700,
                color: "#f0f0f0",
                textTransform: "capitalize",
              }}
            >
              TASK INFOS
            </Typography>
          </Box>
          <IconButton onClick={handleReportDialogClose}>
            <Cancel sx={{ color: "#f0f0f0" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "#001f3f",
            overflow: "scroll", // Allows scrolling
            "::-webkit-scrollbar": {
              display: "none", // Hides scrollbar for WebKit browsers
            },
            scrollbarWidth: "none", // Hides scrollbar for Firefox
          }}
        >
          <TaskReportsTable fullWidth taskId={taskId} />
        </DialogContent>
      </Dialog>

      <Dialog
        sx={{ "& .MuiPaper-root": { borderRadius: "1px" } }}
        open={openEditDialog}
        onClose={handleEditDialogClose}
      >
        <DialogTitle
          sx={{
            display: "flex",
            backgroundColor: "#006494",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            {/* <JavascriptIcon sx={{fontSize:"35px", backgroundColor:"#f0f0f0", borderRadius:"2px"}}/> */}
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 700,
                color: "#f0f0f0",
                textTransform: "capitalize",
              }}
            >
              Editing & Configurations
            </Typography>
          </Box>
          <IconButton onClick={handleEditDialogClose}>
            <Cancel sx={{ color: "#f0f0f0" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "#001f3f",
            overflow: "scroll", // Allows scrolling
            "::-webkit-scrollbar": {
              display: "none", // Hides scrollbar for WebKit browsers
            },
            scrollbarWidth: "none", // Hides scrollbar for Firefox
          }}
        >
          <TextField
            label="Task Name"
            variant="outlined"
            margin="normal"
            fullWidth
            placeholder="Enter your task name here... "
            value={editTask.name}
            onChange={(e) => setEditTask({ ...editTask, name: e.target.value })}
            sx={{
              marginTop: 3,
              mb: 2,
              "& .MuiInputLabel-root": {
                color: "#f0f0f0", // Label color when not focused
                fontSize: "small",
                textTransform: "capitalize",
                "&.Mui-focused": {
                  color: "#f0f0f0", // Label color when focused
                },
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "2px", // Border radius
                "& fieldset": {
                  borderColor: "#dcdcdc", // Border color when not focused
                },
                "&:hover fieldset": {
                  borderColor: "#e8e8e8", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#f0f0f0", // Border color when focused
                },
              },
            }}
            InputProps={{
              sx: {
                fontSize: 12,
                textTransform: "capitalize",
                color: "#dcdcdc",
                "& input::placeholder": {
                  fontSize: 12, // Change placeholder font size here
                  color: "dcdcdc",
                  textTransform: "capitalize", // Optionally change color
                },
              },
            }}
          />
          <TextField
            label="Amount"
            variant="outlined"
            margin="normal"
            fullWidth
            placeholder="Enter amount"
            value={editTask.amount}
            onChange={(e) =>
              setEditTask({ ...editTask, amount: e.target.value })
            }
            sx={{
              mb: 2,
              "& .MuiInputLabel-root": {
                color: "#f0f0f0", // Label color when not focused
                fontSize: "small",
                textTransform: "capitalize",
                "&.Mui-focused": {
                  color: "#f0f0f0", // Label color when focused
                },
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "2px", // Border radius
                "& fieldset": {
                  borderColor: "#dcdcdc", // Border color when not focused
                },
                "&:hover fieldset": {
                  borderColor: "#e8e8e8", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#f0f0f0", // Border color when focused
                },
              },
            }}
            InputProps={{
              sx: {
                fontSize: 12,
                textTransform: "capitalize",
                color: "#dcdcdc",
                "& input::placeholder": {
                  fontSize: 12, // Change placeholder font size here
                  color: "dcdcdc",
                  textTransform: "capitalize", // Optionally change color
                },
              },
            }}
          />
          <TextField
            label="Redirect_link"
            variant="outlined"
            margin="normal"
            fullWidth
            value={editTask.redirect_link}
            onChange={(e) =>
              setEditTask({ ...editTask, redirect_link: e.target.value })
            }
            error={error}
            helperText={error ? "Redirect link is required." : ""}
            sx={{
              mb: 2,
              "& .MuiInputLabel-root": {
                color: "#f0f0f0", // Label color when not focused
                fontSize: "small",
                textTransform: "capitalize",
                "&.Mui-focused": {
                  color: "#f0f0f0", // Label color when focused
                },
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "2px", // Border radius
                "& fieldset": {
                  borderColor: "#dcdcdc", // Border color when not focused
                },
                "&:hover fieldset": {
                  borderColor: "#e8e8e8", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#f0f0f0", // Border color when focused
                },
              },
            }}
            InputProps={{
              sx: {
                fontSize: 12,
                textTransform: "capitalize",
                color: "#dcdcdc",
                "& input::placeholder": {
                  fontSize: 12, // Change placeholder font size here
                  color: "dcdcdc",
                  textTransform: "capitalize", // Optionally change color
                },
              },
            }}
          />
          <TextField
            label="Description"
            variant="outlined"
            margin="normal"
            multiline
            fullWidth
            rows={4}
            value={editTask.description}
            onChange={(e) =>
              setEditTask({ ...editTask, description: e.target.value })
            }
            error={error}
            helperText={error ? "Description is required." : ""}
            sx={{
              mb: 2,
              "& .MuiInputLabel-root": {
                color: "#f0f0f0", // Label color when not focused
                fontSize: "small",
                textTransform: "capitalize",
                "&.Mui-focused": {
                  color: "#f0f0f0", // Label color when focused
                },
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "2px", // Border radius
                "& fieldset": {
                  borderColor: "#dcdcdc", // Border color when not focused
                },
                "&:hover fieldset": {
                  borderColor: "#e8e8e8", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#f0f0f0", // Border color when focused
                },
              },
            }}
            InputProps={{
              sx: {
                fontSize: 12,
                textTransform: "capitalize",
                color: "#dcdcdc",
                "& input::placeholder": {
                  fontSize: 12, // Change placeholder font size here
                  color: "dcdcdc",
                  textTransform: "capitalize", // Optionally change color
                },
              },
            }}
          />
          <TextField
            label="Payment placeholder details"
            variant="outlined"
            margin="normal"
            fullWidth
            value={editTask.payment_placeholder}
            onChange={(e) =>
              setEditTask({ ...editTask, payment_placeholder: e.target.value })
            }
            error={error}
            helperText={error ? "Payment placeholder details is required." : ""}
            placeholder="Enter Payment placeholder details"
            sx={{
              mb: 2,
              "& .MuiInputLabel-root": {
                color: "#f0f0f0", // Label color when not focused
                fontSize: "small",
                textTransform: "capitalize",
                "&.Mui-focused": {
                  color: "#f0f0f0", // Label color when focused
                },
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "2px", // Border radius
                "& fieldset": {
                  borderColor: "#dcdcdc", // Border color when not focused
                },
                "&:hover fieldset": {
                  borderColor: "#e8e8e8", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#f0f0f0", // Border color when focused
                },
              },
            }}
            InputProps={{
              sx: {
                fontSize: 12,
                textTransform: "capitalize",
                color: "#dcdcdc",
                "& input::placeholder": {
                  fontSize: 12, // Change placeholder font size here
                  color: "dcdcdc",
                  textTransform: "capitalize", // Optionally change color
                },
              },
            }}
          />
          <TextField
            label="Pay Button"
            variant="outlined"
            margin="normal"
            fullWidth
            value={editTask.pay_button_details}
            onChange={(e) =>
              setEditTask({ ...editTask, pay_button_details: e.target.value })
            }
            error={error}
            helperText={error ? "Pay Button is required." : ""}
            placeholder="Enter Pay Button name"
            sx={{
              mb: 2,
              "& .MuiInputLabel-root": {
                color: "#f0f0f0", // Label color when not focused
                fontSize: "small",
                textTransform: "capitalize",
                "&.Mui-focused": {
                  color: "#f0f0f0", // Label color when focused
                },
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "2px", // Border radius
                "& fieldset": {
                  borderColor: "#dcdcdc", // Border color when not focused
                },
                "&:hover fieldset": {
                  borderColor: "#e8e8e8", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#f0f0f0", // Border color when focused
                },
              },
            }}
            InputProps={{
              sx: {
                fontSize: 12,
                textTransform: "capitalize",
                color: "#dcdcdc",
                "& input::placeholder": {
                  fontSize: 12, // Change placeholder font size here
                  color: "dcdcdc",
                  textTransform: "capitalize", // Optionally change color
                },
              },
            }}
          />
          <TextField
            label="Success message"
            variant="outlined"
            margin="normal"
            multiline
            fullWidth
            rows={4}
            value={editTask.success_message}
            onChange={(e) =>
              setEditTask({ ...editTask, success_message: e.target.value })
            }
            error={error}
            placeholder="Enter Success message"
            helperText={error ? "Success message is required." : ""}
            sx={{
              mb: 2,
              "& .MuiInputLabel-root": {
                color: "#f0f0f0", // Label color when not focused
                fontSize: "small",
                textTransform: "capitalize",
                "&.Mui-focused": {
                  color: "#f0f0f0", // Label color when focused
                },
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "2px", // Border radius
                "& fieldset": {
                  borderColor: "#dcdcdc", // Border color when not focused
                },
                "&:hover fieldset": {
                  borderColor: "#e8e8e8", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#f0f0f0", // Border color when focused
                },
              },
            }}
            InputProps={{
              sx: {
                fontSize: 12,
                textTransform: "capitalize",
                color: "#dcdcdc",
                "& input::placeholder": {
                  fontSize: 12, // Change placeholder font size here
                  color: "dcdcdc",
                  textTransform: "capitalize", // Optionally change color
                },
              },
            }}
          />
          <TextField
            label="Loading message"
            variant="outlined"
            margin="normal"
            multiline
            fullWidth
            rows={4}
            value={editTask.loading_message}
            onChange={(e) =>
              setEditTask({ ...editTask, loading_message: e.target.value })
            }
            error={error}
            placeholder="Enter loading message"
            helperText={error ? "Loading message is required." : ""}
            sx={{
              mb: 2,
              "& .MuiInputLabel-root": {
                color: "#f0f0f0", // Label color when not focused
                fontSize: "small",
                textTransform: "capitalize",
                "&.Mui-focused": {
                  color: "#f0f0f0", // Label color when focused
                },
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "2px", // Border radius
                "& fieldset": {
                  borderColor: "#dcdcdc", // Border color when not focused
                },
                "&:hover fieldset": {
                  borderColor: "#e8e8e8", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#f0f0f0", // Border color when focused
                },
              },
            }}
            InputProps={{
              sx: {
                fontSize: 12,
                textTransform: "capitalize",
                color: "#dcdcdc",
                "& input::placeholder": {
                  fontSize: 12, // Change placeholder font size here
                  color: "dcdcdc",
                  textTransform: "capitalize", // Optionally change color
                },
              },
            }}
          />
          <TextField
            label="Session expire message"
            variant="outlined"
            margin="normal"
            multiline
            fullWidth
            rows={4}
            value={editTask.session_expire_message}
            onChange={(e) =>
              setEditTask({
                ...editTask,
                session_expire_message: e.target.value,
              })
            }
            error={error}
            placeholder="Enter Session expire message"
            helperText={error ? "Session expire message is required." : ""}
            sx={{
              mb: 2,
              "& .MuiInputLabel-root": {
                color: "#f0f0f0", // Label color when not focused
                fontSize: "small",
                textTransform: "capitalize",
                "&.Mui-focused": {
                  color: "#f0f0f0", // Label color when focused
                },
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "2px", // Border radius
                "& fieldset": {
                  borderColor: "#dcdcdc", // Border color when not focused
                },
                "&:hover fieldset": {
                  borderColor: "#e8e8e8", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#f0f0f0", // Border color when focused
                },
              },
            }}
            InputProps={{
              sx: {
                fontSize: 12,
                textTransform: "capitalize",
                color: "#dcdcdc",
                "& input::placeholder": {
                  fontSize: 12, // Change placeholder font size here
                  color: "dcdcdc",
                  textTransform: "capitalize", // Optionally change color
                },
              },
            }}
          />
          <TextField
            margin="normal"
            label="Invalid number message"
            placeholder="Enter Invalid number message"
            variant="outlined"
            multiline
            fullWidth
            rows={4}
            value={editTask.invalid_number_message}
            onChange={(e) =>
              setEditTask({
                ...editTask,
                invalid_number_message: e.target.value,
              })
            }
            error={error}
            helperText={error ? "Invalid number message is required." : ""}
            sx={{
              mb: 2,
              "& .MuiInputLabel-root": {
                color: "#f0f0f0", // Label color when not focused
                fontSize: "small",
                textTransform: "capitalize",
                "&.Mui-focused": {
                  color: "#f0f0f0", // Label color when focused
                },
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "2px", // Border radius
                "& fieldset": {
                  borderColor: "#dcdcdc", // Border color when not focused
                },
                "&:hover fieldset": {
                  borderColor: "#e8e8e8", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#f0f0f0", // Border color when focused
                },
              },
            }}
            InputProps={{
              sx: {
                fontSize: 12,
                textTransform: "capitalize",
                color: "#dcdcdc",
                "& input::placeholder": {
                  fontSize: 12, // Change placeholder font size here
                  color: "dcdcdc",
                  textTransform: "capitalize", // Optionally change color
                },
              },
            }}
          />
          <Button
            startIcon={<SaveAltIcon />}
            fullWidth
            sx={{
              //  margin:2,
              color: "#ffffff",
              border: "1px solid #006494",
              //  backgroundColor:"#006494",
              borderRadius: "1px",
              padding: 1.5,
              boxShadow: 0,
              "&:hover": {
                border: "1px solid #004a7c",
                boxShadow: 0,
              },
            }}
            onClick={handleEditTask}
          >
            <Typography
              sx={{ fontSize: 12, fontWeight: "600", color: "#ffffff" }}
            >
              save
            </Typography>
          </Button>
        </DialogContent>
      </Dialog>

      {/* Add Task Dialog */}
      <Dialog
        sx={{ "& .MuiPaper-root": { borderRadius: "1px" } }}
        open={openDialog}
        onClose={handleDialogClose}
      >
        <DialogContent>
          <TextField
            label="Task Name"
            variant="outlined"
            fullWidth
            placeholder="Enter your task name here... "
            value={newTask.name}
            onChange={(e) => setNewTask({ ...newTask, name: e.target.value })}
            sx={{
              mb: 2,
              "& .MuiInputLabel-root": {
                color: "#999", // Label color when not focused
                fontSize: "small",
                textTransform: "capitalize",
                "&.Mui-focused": {
                  color: "#001f3f", // Label color when focused
                },
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "2px", // Border radius
                "& fieldset": {
                  borderColor: "#ddd", // Border color when not focused
                },
                "&:hover fieldset": {
                  borderColor: "#001f3f", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#001f3f", // Border color when focused
                },
              },
            }}
            InputProps={{
              sx: {
                fontSize: 12,
                textTransform: "capitalize",
                color: "#333",
                "& input::placeholder": {
                  fontSize: 12, // Change placeholder font size here
                  color: "#555",
                  textTransform: "capitalize", // Optionally change color
                },
              },
            }}
          />
          <TextField
            label="Amount"
            variant="outlined"
            fullWidth
            placeholder="Enter amount"
            value={newTask.amount}
            onChange={(e) => setNewTask({ ...newTask, amount: e.target.value })}
            sx={{
              mb: 2,
              "& .MuiInputLabel-root": {
                color: "#999", // Label color when not focused
                fontSize: "small",
                textTransform: "capitalize",
                "&.Mui-focused": {
                  color: "#001f3f", // Label color when focused
                },
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "2px", // Border radius
                "& fieldset": {
                  borderColor: "#ddd", // Border color when not focused
                },
                "&:hover fieldset": {
                  borderColor: "#001f3f", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#001f3f", // Border color when focused
                },
              },
            }}
            InputProps={{
              sx: {
                fontSize: 12,
                textTransform: "capitalize",
                color: "#333",
                "& input::placeholder": {
                  fontSize: 12, // Change placeholder font size here
                  color: "#555",
                  textTransform: "capitalize", // Optionally change color
                },
              },
            }}
          />

          <TextField
            label="Description"
            variant="outlined"
            multiline
            fullWidth
            rows={4}
            value={newTask.description}
            onChange={(e) =>
              setNewTask({ ...newTask, description: e.target.value })
            }
            error={error}
            helperText={error ? "Description is required." : ""}
            sx={{
              mb: 2,
              "& .MuiInputLabel-root": {
                color: "#999", // Label color when not focused
                fontSize: "small",
                textTransform: "capitalize",
                "&.Mui-focused": {
                  color: "#001f3f", // Label color when focused
                },
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "2px", // Border radius
                "& fieldset": {
                  borderColor: "#ddd", // Border color when not focused
                },
                "&:hover fieldset": {
                  borderColor: "#001f3f", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#001f3f", // Border color when focused
                },
              },
            }}
            InputProps={{
              sx: {
                fontSize: 12,
                textTransform: "capitalize",
                color: "#333",
                "& input::placeholder": {
                  fontSize: 12, // Change placeholder font size here
                  color: "#555",
                  textTransform: "capitalize", // Optionally change color
                },
              },
            }}
          />
          <TextField
            label="Redirect_link"
            variant="outlined"
            fullWidth
            value={newTask.redirect_link}
            onChange={(e) =>
              setNewTask({ ...newTask, redirect_link: e.target.value })
            }
            error={error}
            helperText={error ? "Redirect link is required." : ""}
            sx={{
              // mb:2,
              "& .MuiInputLabel-root": {
                color: "#999", // Label color when not focused
                fontSize: "small",
                textTransform: "capitalize",
                "&.Mui-focused": {
                  color: "#001f3f", // Label color when focused
                },
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "2px", // Border radius
                "& fieldset": {
                  borderColor: "#ddd", // Border color when not focused
                },
                "&:hover fieldset": {
                  borderColor: "#001f3f", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#001f3f", // Border color when focused
                },
              },
            }}
            InputProps={{
              sx: {
                fontSize: 12,
                textTransform: "capitalize",
                color: "#333",
                "& input::placeholder": {
                  fontSize: 12, // Change placeholder font size here
                  color: "#555",
                  textTransform: "capitalize", // Optionally change color
                },
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>
            <Typography
              sx={{ fontSize: 12, fontWeight: "500", color: "#6c757d" }}
            >
              cancel
            </Typography>
          </Button>
          <Button
            sx={{
              color: "#ffffff",
              backgroundColor: "#006494",
              borderRadius: "1px",
              padding: 1,
              boxShadow: 0,
              "&:hover": {
                backgroundColor: "#004a7c",
              },
            }}
            onClick={handleAddTask}
          >
            <Typography
              sx={{ fontSize: 12, fontWeight: "500", color: "#ffffff" }}
            >
              create new task
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        sx={{ "& .MuiPaper-root": { borderRadius: "1px" } }}
        open={openSnippestDialog}
        onClose={handleSnippestDialogClose}
      >
        <DialogTitle
          sx={{
            display: "flex",
            backgroundColor: "#006494",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            <JavascriptIcon
              sx={{
                fontSize: "35px",
                backgroundColor: "#f0f0f0",
                borderRadius: "2px",
              }}
            />
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 500,
                color: "#f0f0f0",
                textTransform: "capitalize",
              }}
            >
              Javascript pixel code
            </Typography>
          </Box>
          <IconButton onClick={handleSnippestDialogClose}>
            <Cancel sx={{ color: "#f0f0f0" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#001f3f" }}>
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: "center",
              fontWeight: 500,
              color: "#f0f0f0",
              textTransform: "capitalize",
              paddingTop: 2,
            }}
          >
            Copy and paste the following JS code snippest before the end of the
            head tag of your website
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              textAlign: "center",
              fontWeight: 400,
              color: "#f0f0f0",
              textTransform: "capitalize",
              paddingTop: 2,
            }}
          >
            Click Javascript link to copy
          </Typography>
          {selectedTask && (
            <Box sx={{ backgroundColor: "#006494", padding: 3, marginTop: 2 }}>
              <Button
                onClick={handleCopyToClipboard(selectedTask.link)}
                sx={{ flexDirection: "column", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                    textAlign: "center",
                    fontWeight: 400,
                    color: "#f0f0f0",
                    marginBottom: "10px",
                    marginTop: "10px",
                  }}
                >{`<!-- Pixel Code - https://payment.mishe.online/-->`}</Typography>
                <Typography
                  sx={{
                    fontSize: "13px",
                    textAlign: "center",
                    fontWeight: 400,
                    color: "#f0f0f0",
                    textTransform: "lowercase",
                  }}
                >{`<script src="${selectedTask.link}"></script>`}</Typography>
                <Typography
                  sx={{
                    fontSize: "13px",
                    textAlign: "center",
                    fontWeight: 400,
                    color: "#f0f0f0",
                    marginBottom: "10px",
                    marginTop: "10px",
                  }}
                >{`<!-- END Pixel Code -->`}</Typography>
              </Button>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Tasks;

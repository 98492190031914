import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import React, { useEffect, useState } from "react";
import { Delete as DeleteIcon, Cancel } from "@mui/icons-material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import useTasks, { deleteUser, fetchUsers } from "../components/UseHooks";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SearchIcon from "@mui/icons-material/Search";

const UserManangement = () => {
  const [newUser, setNewUser] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [users, setUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [assignError, setAssignError] = useState(false);
  const [assignSuccess, setAssignSuccess] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [id, setId] = useState("");
  const [task, setTask] = useState([]);
  const queryClient = useQueryClient();
  const token = localStorage.getItem("token");

  // Handle opening/closing the dialog
  const handleDialogOpen = () => setOpenDialog(true);
  const handleDialogClose = () => setOpenDialog(false);

  const handleAssignDialogOpen = (userId) => {
    setId(userId);
    setOpenAssignDialog(true);
  };
  const handleAssignDialogClose = () => {
    setOpenAssignDialog(false);
  };

  const mutation = useMutation((data) =>
    axios.post(
      "https://publishers.skano.agency/auth/api/v1/normal-user/register",
      data,
      {
        headers: {
          Authorization: `Token ${token}`, // Include the token in the header
          "Content-Type": "application/json",
        },
      },
    ),
  );

  const handleRegisterUser = () => {
    mutation.mutate(newUser, {
      onSuccess: (response) => {
        setOpenDialog(false);
        setSuccess(true); // Reset copy status when a new link is generated
      },
      onError: (error) => {
        setOpenDialog(false);
        setError(true);
      },
    });
  };

  const { data, isLoading, isError, error1, refetch } = useQuery(
    "users",
    fetchUsers,
  );
  const {
    data: tasks,
    isLoading: taskIsloading,
    isError: taskIsError,
    error1: taskError1,
    refetch: taskRefetch,
  } = useTasks();

  useEffect(() => {
    if (data && tasks) {
      setUsers(data); // Update tasks with fetched data
      setTask(tasks);
    }
  }, [data, tasks]);

  const [searchQueryTaks, setSearchQueryTask] = useState("");
  const [filteredTask, setFilteredTask] = useState([]);

  const handleSearchTask = (query) => {
    setSearchQueryTask(query);
    setFilteredTask(
      task.filter(
        (task) =>
          task.name.toLowerCase().includes(query.toLowerCase()) ||
          task.description.toLowerCase().includes(query.toLowerCase()) ||
          task.amount.toLowerCase().includes(query.toLowerCase()),
      ),
    );
  };

  const assignMutation = useMutation((data) =>
    axios.post(
      `https://publishers.skano.agency/tasks/api/v1/${data.id}/assign/`,
      { user_id: id },
      {
        headers: {
          Authorization: `Token ${token}`, // Include the token in the header
          "Content-Type": "application/json",
        },
      },
    ),
  );

  const handleTaskSelection = (task) => {
    assignMutation.mutate(task, {
      onSuccess: (response) => {
        setOpenAssignDialog(false);
        setAssignSuccess(true); // Reset copy status when a new link is generated
      },
      onError: (error) => {
        setOpenAssignDialog(false);
        setAssignError(true);
      },
    });
    // Perform assignment logic here
  };

  // Mutation for deleting a task
  const deleteUserMutation = useMutation(deleteUser, {
    onSuccess: () => {
      // Invalidate the tasks query to refresh the task list after deletion
      queryClient.invalidateQueries("tasks");
      setDeleteSuccess(true);
    },
    onError: (error) => {
      setDeleteError(true);
    },
  });

  // Handler for delete button
  const handleDelete = (userId) => {
    deleteUserMutation.mutate(userId);
  };

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("username");

  // Handle sorting functionality
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedUsers = Array.isArray(users)
    ? users.sort((a, b) => {
        if (orderBy === "username") {
          const nameA = a.name || ""; // Default to an empty string if undefined
          const nameB = b.name || ""; // Default to an empty string if undefined
          return order === "asc"
            ? nameA.localeCompare(nameB)
            : nameB.localeCompare(nameA);
        }
        return 0; // No sorting for other fields in this example
      })
    : [];
  // Sort data based on the current order
  // Ensure tasks is an array before sorting
  /*  const sortedUsers = Array.isArray(users)
    ? users.sort((a, b) => {
        if (orderBy === "username") {
          return order === "asc"
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name);
        }
        return 0; // For simplicity, we only sort by name here
      })
    : [];
*/
  // Handle search filter
  const filteredUsers = sortedUsers?.filter(
    (user) =>
      user.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase()),
    // task.link.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination handler
  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) =>
    setRowsPerPage(parseInt(event.target.value, 10));

  useEffect(() => {
    const interval = setInterval(() => {
      refetch(); // Refetch data every 5 seconds (5000 ms)
      taskRefetch();
    }, 5000);

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [refetch, taskRefetch]);

  useEffect(() => {
    if (
      error ||
      success ||
      assignError ||
      assignSuccess ||
      deleteError ||
      deleteSuccess
    ) {
      const timer = setTimeout(() => {
        setError(false);
        setSuccess(false);
      }, 7000); // Message will disappear after 5 seconds

      return () => clearTimeout(timer);
    }
  }, [error, success, assignError, assignSuccess, deleteError, deleteSuccess]);

  if (isLoading || taskIsloading)
    return (
      <Box sx={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <Typography
          style={{ fontSize: "14px", fontWeight: "500", color: "#ffff" }}
        >
          Loading.........
        </Typography>
      </Box>
    );

  if (isError || taskIsError)
    return (
      <Box
        style={{
          backgroundColor: "#f8d7da",
          padding: "15px",
          borderRadius: "2px",
          border: "1px solid #f5c6cb",
          marginTop: "10px",
          marginBottom: "10px",
          maxWidth: "500px",
        }}
      >
        <Typography style={{ color: "#721c24", fontSize: "12px" }}>
          Error: {error1.message || taskError1.message}
        </Typography>
      </Box>
    );

  return (
    <Box
      component="main"
      sx={{
        overflow: "scroll", // Allows scrolling
        "::-webkit-scrollbar": {
          display: "none", // Hides scrollbar for WebKit browsers
        },
        scrollbarWidth: "none", // Hides scrollbar for Firefox
        marginTop: 2,
        padding: 2,
      }}
    >
      <Box sx={{ mb: 4 }}>
        <Typography
          style={{
            fontSize: 16,
            color: "#f5f5f5",
            fontWeight: "700",
            textTransform: "uppercase",
          }}
          variant="h4"
          component="h1"
          gutterBottom
        >
          Effortless User Management
        </Typography>
        <Typography
          style={{
            fontSize: 12,
            color: "#f0f0f0",
            fontWeight: "400",
            textTransform: "capitalize",
          }}
          variant="subtitle1"
          color="textSecondary"
        >
          Simplify user registration and task assignment in one place.
        </Typography>
      </Box>

      {success && (
        <Box
          style={{
            backgroundColor: "#d4edda",
            padding: "15px",
            borderRadius: "2px",
            border: "1 solid #c3e6cb",
            marginTop: "10px",
            marginBottom: "10px",
            maxWidth: "500px",
          }}
        >
          <Typography style={{ color: "#155724", fontSize: "12px" }}>
            User registered: New User has been successfully created.
          </Typography>
        </Box>
      )}

      {error && (
        <Box
          style={{
            backgroundColor: "#f8d7da",
            padding: "15px",
            borderRadius: "2px",
            border: "1px solid #f5c6cb",
            marginTop: "10px",
            marginBottom: "10px",
            maxWidth: "500px",
          }}
        >
          <Typography style={{ color: "#721c24", fontSize: "12px" }}>
            User registration Failed: An error occurred while trying to register
            user.
          </Typography>
        </Box>
      )}
      {assignSuccess && (
        <Box
          style={{
            backgroundColor: "#d4edda",
            padding: "15px",
            borderRadius: "2px",
            border: "1 solid #c3e6cb",
            marginTop: "10px",
            marginBottom: "10px",
            maxWidth: "500px",
          }}
        >
          <Typography style={{ color: "#155724", fontSize: "12px" }}>
            Assign successfully: Task has been successfully assgined.
          </Typography>
        </Box>
      )}

      {assignError && (
        <Box
          style={{
            backgroundColor: "#f8d7da",
            padding: "15px",
            borderRadius: "2px",
            border: "1px solid #f5c6cb",
            marginTop: "10px",
            marginBottom: "10px",
            maxWidth: "500px",
          }}
        >
          <Typography style={{ color: "#721c24", fontSize: "12px" }}>
            Assign Failed: An error occurred while trying to assign task.
          </Typography>
        </Box>
      )}
      {deleteError && (
        <Box
          style={{
            backgroundColor: "#f8d7da",
            padding: "15px",
            borderRadius: "2px",
            border: "1px solid #f5c6cb",
            marginTop: "10px",
            marginBottom: "10px",
            maxWidth: "500px",
          }}
        >
          <Typography style={{ color: "#721c24", fontSize: "12px" }}>
            Delete Failed: An error occurred while trying to delete user.
          </Typography>
        </Box>
      )}

      {deleteSuccess && (
        <Box
          style={{
            backgroundColor: "#d4edda",
            padding: "15px",
            borderRadius: 2,
            border: "1 solid #c3e6cb",
            marginTop: "10px",
            marginBottom: "10px",
            maxWidth: "500px",
          }}
        >
          <Typography style={{ color: "#155724", fontSize: "12px" }}>
            User Deleted: User has been successfully removed.
          </Typography>
        </Box>
      )}

      <Box sx={{ mb: 4 }}>
        <Button
          elevation={0}
          disableElevation
          startIcon={<PersonAddIcon />}
          sx={{
            color: "#ffffff",
            backgroundColor: "#006494",
            borderRadius: "1px",
            padding: 1,
            boxShadow: 0,
            "&:hover": {
              backgroundColor: "#004a7c",
            },
          }}
          onClick={handleDialogOpen}
        >
          <Typography
            sx={{ fontSize: 12, fontWeight: "500", color: "#ffffff" }}
          >
            Add new user
          </Typography>
        </Button>
      </Box>

      <Toolbar sx={{ mb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <TextField
              label="Search"
              variant="outlined"
              fullWidth
              placeholder="Search ... "
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{
                mb: 2,
                "& .MuiInputLabel-root": {
                  color: "#999", // Label color when not focused
                  fontSize: "small",
                  textTransform: "capitalize",
                  "&.Mui-focused": {
                    color: "#f5f5f5", // Label color when focused
                  },
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "2px", // Border radius
                  "& fieldset": {
                    borderColor: "#ddd", // Border color when not focused
                  },
                  "&:hover fieldset": {
                    borderColor: "#f5f5f5", // Border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#f5f5f5", // Border color when focused
                  },
                },
              }}
              InputProps={{
                sx: {
                  fontSize: 12,
                  textTransform: "capitalize",
                  color: "#dcdcdc",
                  "& input::placeholder": {
                    fontSize: 12, // Change placeholder font size here
                    color: "#555",
                    textTransform: "capitalize", // Optionally change color
                  },
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Toolbar>
      <Box elevation={0} sx={{ mb: 2 }}>
        <TableContainer
          sx={{
            backgroundColor: "#012d5a", // Keeps the table within the width of its container
            overflow: "scroll", // Allows scrolling
            "::-webkit-scrollbar": {
              display: "none", // Hides scrollbar for WebKit browsers
            },
            scrollbarWidth: "none", // Hides scrollbar for Firefox
            // padding:2
          }}
          elevation={0}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: 10,
                    textTransform: "uppercase",
                    fontWeight: "700",
                    color: "#f5f5f5",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === "username"}
                    direction={orderBy === "username" ? order : "asc"}
                    onClick={() => handleRequestSort("username")}
                    style={{ color: "#f5f5f5" }}
                  >
                    username
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  sx={{
                    fontSize: 10,
                    textTransform: "uppercase",
                    fontWeight: "700",
                    color: "#f5f5f5",
                  }}
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: 10,
                    textTransform: "uppercase",
                    fontWeight: "700",
                    color: "#f5f5f5",
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user) => (
                  <TableRow key={user.id}>
                    <TableCell
                      sx={{
                        fontSize: 12,
                        textTransform: "capitalize",
                        fontWeight: "400",
                        color: "#f5f5f5",
                      }}
                    >
                      {user.username}
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: 12, fontWeight: "400", color: "#f5f5f5" }}
                    >
                      {user.email}
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          padding: "auto",
                        }}
                      >
                        <IconButton
                          style={{
                            borderRadius: "2px",
                            color: "#ffffff",
                            backgroundColor: "#4CAF50", // Light green background
                            "&:hover": {
                              backgroundColor: "#388E3C", // Darker green on hover
                            },
                          }}
                          onClick={() => handleAssignDialogOpen(user.id)}
                          sx={{ mr: 1 }}
                        >
                          <AssignmentIcon />
                        </IconButton>
                        <IconButton
                          style={{
                            borderRadius: "2px",
                            color: "#ffffff",
                            backgroundColor: "#FF4C4C", // Light red background
                            "&:hover": {
                              backgroundColor: "#FF0000", // Darker red on hover
                            },
                          }}
                          onClick={() => handleDelete(user.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* Pagination */}
        <TablePagination
          sx={{
            backgroundColor: "#012d5a",
            overflow: "auto", // Allows scrolling if needed
            "::-webkit-scrollbar": {
              display: "none", // Hides scrollbar for WebKit browsers
            },
            scrollbarWidth: "none", // Hides scrollbar for Firefox
            fontSize: 12, // Adjust font size
            textTransform: "capitalize", // Capitalizes the text
            color: "#f0f0f0",
            "& .MuiTablePagination-toolbar": {
              backgroundColor: "#012d5a",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 16px", // Adjust padding for toolbar
            },
            "& .MuiTablePagination-selectLabel": {
              backgroundColor: "#012d5a",
              fontSize: 12, // Adjust font size for label
            },
            "& .MuiTablePagination-select": {
              backgroundColor: "#012d5a",
              fontSize: 12, // Adjust font size for select dropdown
              color: "#f0f0f0", // Color of the select dropdown text
            },
            "& .MuiTablePagination-displayedRows": {
              backgroundColor: "#012d5a",
              fontSize: 12, // Adjust font size for displayed rows text
            },
            "& .MuiTablePagination-input": {
              backgroundColor: "#012d5a",
            },
            "& .MuiTablePagination-actions": {
              backgroundColor: "#012d5a",
              fontSize: 12, // Adjust font size for pagination actions
            },
            "& .MuiSelect-select": {
              color: "#f0f0f0", // Color of the select value text
            },
            "& .MuiMenuItem-root": {
              fontSize: 12, // Font size for menu items
              color: "#f0f0f0", // Default text color for menu items
              "&:hover": {
                backgroundColor: "#f0f0f0", // Change background color on hover
                color: "#0013f3",
              },
              "&.Mui-selected": {
                backgroundColor: "#ffffff", // Change background color when selected
                color: "#001f3f3",
              },
            },
          }}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: "#006494",
                  borderRadius: "1px", // Change border radius of the menu items container
                  boxShadow: "none", // Remove box shadow of the menu items box
                  "& .MuiMenuItem-root": {
                    fontSize: 12, // Font size for menu items
                    color: "#f0f0f0", // Default text color for menu items
                    "&:hover": {
                      backgroundColor: "#DCDCDC", // Change background color on hover
                      color: "#333333",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "#f5f5f5", // Change background color when selected
                      color: "#333333",
                    },
                  },
                },
              },
            },
          }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Dialog
        sx={{ "& .MuiPaper-root": { borderRadius: "1px" } }}
        open={openDialog}
        onClose={handleDialogClose}
      >
        <DialogTitle
          sx={{
            display: "flex",
            backgroundColor: "#006494",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            {/* <JavascriptIcon sx={{fontSize:"35px", backgroundColor:"#f0f0f0", borderRadius:"2px"}}/> */}
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 700,
                color: "#f0f0f0",
                textTransform: "capitalize",
              }}
            >
              New User Registration
            </Typography>
          </Box>
          <IconButton onClick={handleDialogClose}>
            <Cancel sx={{ color: "#f0f0f0" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "#001f3f",
            overflow: "scroll", // Allows scrolling
            "::-webkit-scrollbar": {
              display: "none", // Hides scrollbar for WebKit browsers
            },
            scrollbarWidth: "none", // Hides scrollbar for Firefox
          }}
        >
          <TextField
            label="Username"
            value={newUser.username}
            onChange={(e) =>
              setNewUser({ ...newUser, username: e.target.value })
            }
            margin="normal"
            variant="outlined"
            fullWidth
            placeholder="Enter username"
            sx={{
              marginTop: 3,
              mb: 2,
              "& .MuiInputLabel-root": {
                color: "#f0f0f0", // Label color when not focused
                fontSize: "small",
                textTransform: "capitalize",
                "&.Mui-focused": {
                  color: "#f0f0f0", // Label color when focused
                },
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "2px", // Border radius
                "& fieldset": {
                  borderColor: "#dcdcdc", // Border color when not focused
                },
                "&:hover fieldset": {
                  borderColor: "#e8e8e8", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#f0f0f0", // Border color when focused
                },
              },
            }}
            InputProps={{
              sx: {
                fontSize: 12,
                textTransform: "capitalize",
                color: "#dcdcdc",
                "& input::placeholder": {
                  fontSize: 12, // Change placeholder font size here
                  color: "dcdcdc",
                  textTransform: "capitalize", // Optionally change color
                },
              },
            }}
          />
          <TextField
            label="Email"
            value={newUser.email}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
            margin="normal"
            variant="outlined"
            fullWidth
            placeholder="Enter email"
            sx={{
              marginTop: 3,
              mb: 2,
              "& .MuiInputLabel-root": {
                color: "#f0f0f0", // Label color when not focused
                fontSize: "small",
                textTransform: "capitalize",
                "&.Mui-focused": {
                  color: "#f0f0f0", // Label color when focused
                },
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "2px", // Border radius
                "& fieldset": {
                  borderColor: "#dcdcdc", // Border color when not focused
                },
                "&:hover fieldset": {
                  borderColor: "#e8e8e8", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#f0f0f0", // Border color when focused
                },
              },
            }}
            InputProps={{
              sx: {
                fontSize: 12,
                textTransform: "capitalize",
                color: "#dcdcdc",
                "& input::placeholder": {
                  fontSize: 12, // Change placeholder font size here
                  color: "dcdcdc",
                  textTransform: "capitalize", // Optionally change color
                },
              },
            }}
          />
          <TextField
            label="Password"
            type="password"
            value={newUser.password}
            onChange={(e) =>
              setNewUser({ ...newUser, password: e.target.value })
            }
            margin="normal"
            variant="outlined"
            fullWidth
            placeholder="Enter password"
            sx={{
              marginTop: 3,
              mb: 2,
              "& .MuiInputLabel-root": {
                color: "#f0f0f0", // Label color when not focused
                fontSize: "small",
                textTransform: "capitalize",
                "&.Mui-focused": {
                  color: "#f0f0f0", // Label color when focused
                },
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "2px", // Border radius
                "& fieldset": {
                  borderColor: "#dcdcdc", // Border color when not focused
                },
                "&:hover fieldset": {
                  borderColor: "#e8e8e8", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#f0f0f0", // Border color when focused
                },
              },
            }}
            InputProps={{
              sx: {
                fontSize: 12,
                textTransform: "capitalize",
                color: "#dcdcdc",
                "& input::placeholder": {
                  fontSize: 12, // Change placeholder font size here
                  color: "dcdcdc",
                  textTransform: "capitalize", // Optionally change color
                },
              },
            }}
          />

          <Button
            startIcon={<SaveAltIcon />}
            fullWidth
            sx={{
              mb: 2,
              color: "#ffffff",
              border: "1px solid #006494",
              //  backgroundColor:"#006494",
              borderRadius: "1px",
              padding: 1.5,
              boxShadow: 0,
              "&:hover": {
                border: "1px solid #004a7c",
                boxShadow: 0,
              },
            }}
            onClick={handleRegisterUser}
          >
            <Typography
              sx={{ fontSize: 12, fontWeight: "600", color: "#ffffff" }}
            >
              save
            </Typography>
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        sx={{ "& .MuiPaper-root": { borderRadius: "1px" } }}
        open={openAssignDialog}
        onClose={handleAssignDialogClose}
      >
        <DialogTitle
          sx={{
            display: "flex",
            backgroundColor: "#006494",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            {/* <JavascriptIcon sx={{fontSize:"35px", backgroundColor:"#f0f0f0", borderRadius:"2px"}}/> */}
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 700,
                color: "#f0f0f0",
                textTransform: "capitalize",
              }}
            >
              Assign user to a task
            </Typography>
          </Box>
          <IconButton onClick={handleAssignDialogClose}>
            <Cancel sx={{ color: "#f0f0f0" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "#001f3f",
            overflow: "scroll", // Allows scrolling
            "::-webkit-scrollbar": {
              display: "none", // Hides scrollbar for WebKit browsers
            },
            scrollbarWidth: "none", // Hides scrollbar for Firefox
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            placeholder="search task..."
            value={searchQueryTaks}
            onChange={(e) => handleSearchTask(e.target.value)}
            sx={{
              marginTop: 3,
              mb: 2,
              "& .MuiInputLabel-root": {
                color: "#f0f0f0", // Label color when not focused
                fontSize: "small",
                textTransform: "capitalize",
                "&.Mui-focused": {
                  color: "#f0f0f0", // Label color when focused
                },
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "2px", // Border radius
                "& fieldset": {
                  borderColor: "#dcdcdc", // Border color when not focused
                },
                "&:hover fieldset": {
                  borderColor: "#e8e8e8", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#f0f0f0", // Border color when focused
                },
              },
            }}
            InputProps={{
              sx: {
                fontSize: 12,
                textTransform: "capitalize",
                color: "#dcdcdc",
                "& input::placeholder": {
                  fontSize: 12, // Change placeholder font size here
                  color: "dcdcdc",
                  textTransform: "capitalize", // Optionally change color
                },
              },
            }}
          />

          <List
            sx={{
              maxHeight: 200,
              overflowY: "auto",
              backgroundColor: "#001f3f",
              // "#006494",
              // border: "1px solid #dcdcdc",
              // borderRadius: "2px",
            }}
          >
            {filteredTask.map((task) => (
              <ListItem
                key={task.id}
                button
                onClick={() => handleTaskSelection(task)}
                sx={{
                  padding: 1,
                  backgroundColor: "#006494",
                  borderBottom: "1px solid #fff",
                  borderRadius: "1px",
                  boxShadow: 0,
                  "&:hover": {
                    backgroundColor: "#004a7c",
                    boxShadow: 0,
                  },
                }}
              >
                <ListItemText
                  primary={
                    <Typography
                      style={{
                        fontSize: 12,
                        textTransform: "capitalize",
                        fontWeight: 400,
                        color: "#f0f0f0",
                      }}
                    >
                      {task.name}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default UserManangement;
